<template class="">
    <!-- Header Section -->
    <div class="bg-white text-gray-800">
        <slot />
    </div>
    <!-- Footer Section -->
    <footer class="bg-gray-800 text-white py-4">
        <div class="container mx-auto text-center">
            <p>&copy; 2023 SAAS Product. All rights reserved.</p>
        </div>
    </footer>
</template>